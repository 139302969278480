import { useState } from "react"
import { createClient } from "contentful"

import config from "../config"

import { ContextStatus, initialContextStatus } from "../../types/statusTypes"
import {
  Color,
  Composition,
  CompositionMaterial,
  Device,
  Material,
  Mockup,
  OS,
  Platform,
} from "../../types/contentfulTypes"
import Helpers from "../Helpers"

export interface MaterialCount {
  Real?: number
  Clay?: number
  Glass?: number
  Angles?: number
}

export interface DevicesData {
  [os: string]: Device[]
}

const useContentful = () => {
  const [status, setStatus] = useState<ContextStatus>(initialContextStatus)
  const client = createClient(config.contentful)

  // Used in /collections/os page
  const getCollections = async (platform: Platform, os: OS) => {
    setStatus({
      ...status,
      state: "pending",
    })

    try {
      const entries = await client.getEntries({
        content_type: "device",
        select: "fields",
        "fields.platform": platform,
        "fields.os": os,
        order: "-fields.releaseDate",
        include: 2,
      })

      if (entries.items.length < 1) {
        setStatus({
          ...status,
          state: "resolved",
          message: `Successfully fetched collection`,
        })
        return
      }

      const devices = entries.items.map((device: any) => {
        const mockups = device.fields?.mockups?.map((mockup: any) => {
          const color = mockup.fields.color?.fields

          const images = mockup.fields.images?.map((image: any) => image.fields)

          const sanitizedMockup = {
            ...mockup.fields,
            color,
            images,
          }

          return sanitizedMockup
        })

        const sanitizedDevice = {
          ...device.fields,
          mockups,
        }

        setStatus({
          ...status,
          state: "resolved",
          message: `Successfully fetched collection`,
        })

        return sanitizedDevice
      })

      return devices as Device[]
    } catch (error) {
      console.error(`Error in getCollections`, error)
      setStatus({
        ...status,
        state: "rejected",
        error: `Error getting collections from Contentful`,
      })
    }
  }

  // Used in /mockups page
  const getDevices = async () => {
    setStatus({
      ...status,
      state: "pending",
    })

    try {
      const entries = await client.getEntries({
        content_type: "device",
        select: "fields",
        order: "-fields.releaseDate",
        limit: 40,
      })

      if (entries.items.length < 1) {
        setStatus({
          ...status,
          state: "resolved",
          message: `Successfully fetched devices`,
        })
        return
      }

      const items = entries.items.map((entry: any) => {
        const mockups = entry.fields.mockups?.map((mockup: any) => {
          const color = mockup.fields?.color?.fields
          const images = mockup.fields?.images?.map((image: any) => image.fields)
          const sanitizedMockup = {
            ...mockup.fields,
            color,
            images,
          }
          return sanitizedMockup
        }) as Mockup[]

        const materials = entry.fields.mockups?.map((mockup: any) => mockup.fields.material)

        const materialCounts: MaterialCount = {}
        materials?.forEach((material: Material) => {
          materialCounts[material] = (materialCounts[material] || 0) + 1
        })

        mockups?.forEach(mockup => {
          if (mockup.images.length > (materialCounts["Angles"] || 0)) {
            materialCounts["Angles"] = mockup.images.length
          }
        })

        let mockupsCount = 0
        mockups?.map(mockup => (mockupsCount += mockup.images.length))

        const device = {
          ...entry.fields,
          mockups,
        } as Device

        return {
          device,
          materialCounts,
          mockupsCount,
        }
      })

      setStatus({
        ...status,
        state: "resolved",
        message: `Successfully fetched devices`,
      })

      return items
    } catch (error) {
      console.error(`Error in getDevices`, error)
      setStatus({
        ...status,
        state: "rejected",
        error: `Error getting devices from Contentful`,
      })
    }
  }

  // Used for each mockup show/product page
  const getMockups = async (slug: string) => {
    setStatus({
      ...status,
      state: "pending",
    })

    try {
      const entries = await client.getEntries({
        content_type: "device",
        select: "fields",
        "fields.slug": slug,
        include: 2,
      })

      if (entries.items.length < 1) {
        setStatus({
          ...status,
          state: "resolved",
          message: `Successfully fetched mockups`,
        })
        return
      }

      let colors: Color[] = []
      let mockupsCount = 0
      let anglesCount = 0

      const devices = entries.items.map((device: any) => {
        const image = device.fields?.image?.fields
        const mockups = device.fields?.mockups?.map((mockup: any) => {
          const color = mockup.fields?.color?.fields
          colors.push(color)

          const images = mockup.fields?.images?.map((image: any) => image.fields)
          if (mockup.fields?.images?.length > anglesCount) {
            anglesCount = mockup.fields?.images?.length
          }
          mockupsCount += mockup.fields?.images?.length

          const sanitizedMockup = {
            ...mockup.fields,
            color,
            images,
          }
          return sanitizedMockup as Mockup
        })

        const sanitizedDevice = {
          ...device.fields,
          mockups,
          image,
        }

        return sanitizedDevice as Device
      })

      setStatus({
        ...status,
        state: "resolved",
        message: `Successfully fetched mockups`,
      })

      return {
        device: devices[0],
        mockupsCount,
        anglesCount,
        colors,
      }
    } catch (error) {
      console.error(`Error in getMockups`, error)
      setStatus({
        ...status,
        state: "rejected",
        error: `Error getting mockups from Contentful`,
      })
    }
  }

  // Used in MockupsSelectionSection
  const getFeaturedDevices = async () => {
    setStatus({
      ...status,
      state: "pending",
    })

    try {
      const oses = ["iOS", "Android", "macOS", "iPadOS"]

      const devicesData: DevicesData = {}
      await Promise.all(
        oses.map(async (os: string) => {
          const entries = await client.getEntries({
            content_type: "device",
            select: "fields",
            order: "-fields.releaseDate",
            limit: 4,
            include: 2,
            "fields.os": os,
            "fields.isFeatured": true,
          })

          if (entries.items.length < 1) {
            setStatus({
              ...status,
              state: "resolved",
              message: `Successfully fetched devices`,
            })
            return
          }

          const devices = entries.items.map((device: any) => {
            const image = device?.fields?.image?.fields
            const mockups = device.fields.mockups?.map((mockup: any) => {
              const images = mockup.fields?.images?.map((image: any) => image.fields)

              const sanitizedMockup = {
                ...mockup.fields,
                images,
              }
              return sanitizedMockup as Mockup
            })

            const sanitizedDevice = {
              ...device.fields,
              image,
              mockups,
            }
            return sanitizedDevice as Device
          })

          setStatus({
            ...status,
            state: "resolved",
            message: `Successfully fetched devices`,
          })

          if (devices) {
            if (os === "iPadOS" || os === "macOS") {
              // Merge iPadOS and macOS into Desktop category
              devicesData["Desktop"] = devicesData["Desktop"]
                ? devicesData["Desktop"].concat(devices)
                : devices
            } else {
              devicesData[os] = devices
            }
          }
        })
      )

      return devicesData
    } catch (error) {
      console.error(`Error in getMockups`, error)
      setStatus({
        ...status,
        state: "rejected",
        error: `Error getting mockups from Contentful`,
      })
    }
  }

  const getCompositions = async () => {
    setStatus({
      ...status,
      state: "pending",
    })

    try {
      const materials: CompositionMaterial[] = ["Real", "Clay", "Glass", "Card"]

      let compositions: any = {}
      await Promise.all(
        materials.map(async material => {
          const entries = await client.getEntries({
            content_type: "composition",
            select: "fields",
            order: "fields.name",
            "fields.material": material,
          })

          const sanitizedComps = entries.items.map((composition: any) => {
            const image = composition.fields?.image?.fields

            const sanitizedComposition = {
              ...composition.fields,
              image,
            }

            return sanitizedComposition as Composition
          })

          return (compositions[material] = sanitizedComps)
        })
      )

      setStatus({
        ...status,
        state: "resolved",
        message: `Successfully fetched devices`,
      })
      return compositions
    } catch (error) {
      console.error(`Error in getMockups`, error)
      setStatus({
        ...status,
        state: "rejected",
        error: `Error getting mockups from Contentful`,
      })
    }
  }

  return {
    status,
    getCollections,
    getDevices,
    getMockups,
    getFeaturedDevices,
    getCompositions,
  }
}

export default useContentful
